<template>
  <div class="travelStoryDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.homeTitle5") }}</div> 
      <div class="mr-04 fs-025" @click="searchMore">{{ $t("app.More") }}</div>
    </div>
    <div class="distTitle2">{{ $t("app.homeSubtitle5") }}</div>

    <div class="distDiv">
      <div class="distItem" v-for="(item,index) in travelStoryList" :key="index" @click="toDetail(item)">
        <div class="shopInfo">
          <img :src="item.img" class="img" alt="">
          <div class="shopName">{{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "travelStoryPart",
  props: ["travelStoryList"],
  data() {
    return {}
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "detailsPage",
        query: {
          type: item.type,
          id: item.id
        }
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'b',
              index:'4'
          }
      })
    }

  },

}
</script>

<style scoped lang="less">
.travelStoryDiv {
  text-align: start;
  background: white;
  color: black;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;

  .distTitle1 {
    font-size: .37rem;
    font-weight: 600;
    margin-left: 0.4rem;
  }
  
  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.24rem 0.4rem 0rem 0.4rem;
  }

  .distDiv {
    margin-top: 0.24rem;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    height: 4.5rem;
    display: inline-flex;
    background: transparent;
    margin: 0.075rem;
    width: 5.35rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    vertical-align: top;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }


    .shopInfo {
      width: 5.35rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .shopName {
        margin-top: 0.2rem;
        line-height: 0.45rem;
        width: 5.35rem;
        height: 1rem;
        font-size: 0.32rem;
        text-align: start;
        box-sizing: border-box;


        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .img {
        display: flex;
        width: 5.35rem;
        height: 3.42rem;
        border-radius: 0.16rem;
        object-fit: cover;
      }
    }


  }


}

</style>
