<template>
  <div class="categoryDiv ">

    <div class="typeDiv" style="background:linear-gradient(#00000090, #00000000)">
      <div class="typeItem" v-for="(item,index) in categoryList" :key="index" @click="goSearch(item.name,item.index)">

        <div class="title">{{ item.title }}</div>
        <img :src="item.img" class="img">

      </div>

    </div>


  </div>
</template>

<script>

import introduction from "../../assets/img/introduction.png"
import area from "../../assets/img/area.png"
import scenicSpot from "../../assets/img/scenic spot.png"
import shop from "../../assets/img/shop.png"
import ticket from "../../assets/img/ticket.png"


export default {
  name: "categoryBar",
  props: {},
  data() {
    return {
      categoryList: [
        {
          title: this.$t('app.introduction'),
          img: introduction,
          type: 0,
          name:'b',
          index:'4'
        },
        {
          title: this.$t('app.area'),
          img: area,
          type: 0,
          name:'c',
          index:'1'
        },
        {
          title: this.$t('app.scenicSpot'),
          img: scenicSpot,
          type: 0,
          name:'d',
          index:'2'
        },
        {
          title: this.$t('app.shop'),
          img: shop,
          type: 0,
          name:'e',
          index:'5'
        },
        {
          title: this.$t('app.ticket'),
          img: ticket,
          type: 0,
          name:'f',
          index:'6'
        }
      ]
    }
  },
  create(){
    this.$forceUpdate()
  },
  methods: {
    goSearch(name,index){
      this.$emit('goSearch',name,index)
    }
  },

}
</script>

<style scoped lang="less">
.categoryDiv {
  z-index: 999;
  position: absolute;
  top: 1rem;
  //background: black;
  width: 100vw;

  .typeDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    height: 1.02rem;

  }

  .typeItem {
    display: inline-flex;
    background: transparent;
    margin: 0.1rem;
    min-width: 2.8rem;
    height: 0.8rem;
    border-radius: .2rem;
    border: 1px solid #ffffff;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.38rem;
    box-sizing: border-box;
    transform: scale(0.99);

    .title {
      color: white;
      display: flex;
      // font-size: .3rem;
      margin-right: 0.2rem;
      font-weight: 600;
    }

    .img {
      display: flex;
      width: 0.3rem;
      height: 0.34rem;

    }


    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }
  }


}

</style>
