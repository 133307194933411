<template>
  <!-- ticket -->
  <div class="ticketDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.homeTitle4") }}</div>
      <div class="mr-04 fs-025" @click="searchMore">{{ $t("app.More") }}</div>
    </div>
    <div class="distTitle2">{{ $t("app.homeSubtitle4") }}</div>

    <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in ticketList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="ticketInfo">
          <img :src="item.img" class="img" alt="" />
          <div class="ticketName">{{ item.name }}</div>
          <div class="ticketName" style="font-size: 0.28rem; color: #595757">
            {{ item.reviews || 0 }} {{ $t("app.reviews") }}
          </div>

          <div class="price" v-if="Number(item.extra.price) == 0">{{ $t('app.noInventory') }}</div>
          <div class="price" v-else>{{ $t("app.money") }}{{ priceFormat(item.extra.price) }}</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ticketPart",
  props: ["ticketList"],
  data() {
    return {};
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "orderXq",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'f',
              index:'6'
          }
      })
    }
  },
};
</script>

<style scoped lang="less">
.ticketDiv {
  margin-top: 0.4rem;
  text-align: start;
  background: #faf1ed;
  color: black;
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;

  .distTitle1 {
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.4rem;
  }

  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.24rem 0.4rem 0rem 0.4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin: 0.25rem;
    width: 4.25rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }

    .ticketInfo {
      width: 4.25rem;
      background-color: white;
      display: flex;
      flex-direction: column;
      color: black;
      border-radius: 0.16rem;
      .ticketName {
        margin-top: 0.14rem;
        line-height: 0.35rem;
        font-size: 0.32rem;
        text-align: start;
        box-sizing: border-box;
        color: black;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
      }
      .price {
        margin-top: 0.14rem;
        line-height: 0.35rem;
        width: 4.25rem;
        color: black;
        font-size: 0.28rem;
        text-align: start;
        box-sizing: border-box;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
      }

      .img {
        display: flex;
        width: 4.25rem;
        height: 4.25rem;
        object-fit: cover;
        border-top-left-radius: 0.16rem;
        border-top-right-radius: 0.16rem;
      }
    }
  }
}
</style>
