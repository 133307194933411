<template>
  <!-- shore/shop -->
  <div class="shopDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.homeTitle2") }}</div>
      <div class="mr-04 fs-025" @click="searchMore">{{ $t("app.More") }}</div>
    </div>
    <div class="distTitle2">{{ $t("app.homeSubtitle2") }}</div>

    <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in shopList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="shopInfo">
          <img :src="item.img ? item.img.split(',')[0] : ''" class="img" alt="" />
          <div class="shopName">{{ item.name }}</div>
          <div class="shopName" style="font-size: 0.28rem;display: none;">
            {{ $t("app.Rating") }} ： {{ getRandomNumber() }}
          </div>
          <div class="loc">{{ item.extra.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shopPart",
  props: ["shopList"],
  data() {
    return {};
  },
  methods: {
    getRandomNumber() {
      let randomNumber = Math.random() * 0.9 + 4.0;
      return randomNumber.toFixed(1);
    },
    toDetail(item) {
      // console.log(item);
      this.$router.push({
        path: "detailsPage",
        query: {
          // type: item.type,
          type: "5",
          id: item.id,
        },
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'e',
              index:'5'
          }
      })
    }
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
};
</script>

<style scoped lang="less">
.shopDiv {
  margin-top: 0.6rem;
  text-align: start;
  background: #faf1ed;
  color: black;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;

  .distTitle1 {
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.4rem;
  }

  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.24rem 0.4rem 0rem 0.4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin: 0.25rem;
    width: 4.25rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }

    .shopInfo {
      background-color: white;
      margin-right: 0.13rem;
      width: 4.25rem;
      display: flex;
      flex-direction: column;
      border-radius: 0.16rem;
      .shopName {
        margin-top: 0.13rem;
        line-height: 0.35rem;
        // height: 0.27rem;
        width: 4.25rem;
        font-size: 0.32rem;
        text-align: start;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
      }

      .loc {
        margin-top: 0.13rem;
        line-height: 0.35rem;
        font-size: 0.24rem;
        text-align: start;
        box-sizing: border-box;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        height: 0.7rem;
      }

      .img {
        display: flex;
        width: 4.25rem;
        height: 4.25rem;
        object-fit: cover;
        border-top-right-radius: 0.16rem;
        border-top-left-radius: 0.16rem;
      }
    }
  }
}
</style>
