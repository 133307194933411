<template>
    <div class="videoDiv" v-if="videoList && videoList.length > 0">

        <div class="flow-row space-between">
            <div class="distTitle1">{{ $t('app.videoList') }}</div>
            <div class="mr-04 fs-025" @click="searchMore">{{ $t("app.More") }}</div>
        </div>

        <div class="distDiv">
            <div class="distItem" v-for="(item, index) in videoList" :key="index">
                <div class="shopInfo">
                    <VideoPlay  class="video-player vjs-custom-skin" :videoCover="getVideoCover(item.url)" :videoUrl="item.url" :autoplay="false" :loop="false"
                        :muted="true" :width="220" :height="165" :type="'video'" :videoId="item.id"/>
                    <div class="videoName">{{ item.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

   

<script>
import VideoPlay from "./video/ViedoPlayer.vue";

export default {
    name: "videoPart",
    components: {
        VideoPlay,
    },
    props: ["videoList"],
    data() {
        return {
        };
    },
    methods: {
        getVideoCover(videourl){
            // var reg1 = new RegExp(".mp4video","g"); 
            // var reg2 = new RegExp(".mp4","g"); 
            // let result = videourl.replace(reg2,'');
            // result = videourl.replace(reg1,'');
            let result = videourl.substring(0,videourl.lastIndexOf('.'));
            return result+"_cover.jpeg"
        },
        searchMore(){
            this.$router.push('/videoList')
        }
    },

    created(){
       
    },
    filters: {
        getFristText(str) {
            let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
            return rText.replace(/<[^>]+>/g, "").trim();
        },
        filtersText(val) {
            if (val != null && val != "") {
                let reg = new RegExp("<.+?>", "g");
                let arrEntities = {
                    lt: "<",
                    gt: ">",
                    nbsp: " ",
                    amp: "&",
                    quot: '"',
                    ldquo: "“",
                    mdash: "—",
                    rdquo: "”",
                };
                return val
                    .replace(reg, "")
                    .replace(
                        /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                        function (all, t) {
                            return arrEntities[t];
                        }
                    );
                // } else return '';
                // if (val != null && val != '') {
                //     let reg = /<img.*?(?:>|\/>)/gi;
                //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
                //     val = names ? names.join('') : '';
                //     return val;
                // } else return '';
            }
        },
    },
};
</script>
<style scoped lang="less">
.videoDiv {
    text-align: start;
    background: #faf1ed;
    color: black;
    padding-top: 0.4rem;
    margin-top: 0.4rem;

    .distTitle1 {
        font-size: .37rem;
        font-weight: 600;
        margin-left: 0.4rem;
    }

    .distDiv {
        margin-top: 0.24rem;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
    }

    .distItem {
        height: auto;
        display: inline-flex;
        background: transparent;
        margin: 0.075rem;
        width: fill-content;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        &:first-child {
            margin-left: 0.4rem;
        }

        &:last-child {
            margin-right: 0.4rem;
        }


        .shopInfo {
            width: inherit;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .videoName {
                margin-top: 0.2rem;
                line-height: 0.35rem;
                width: 4.25rem;
                height: .58rem;
                font-size: 0.32rem;
                text-align: start;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .img {
                display: flex;
                width: 5.35rem;
                height: 3.42rem;
                object-fit: cover;
            }
        }


    }


}
</style>
  

  