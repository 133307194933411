<template>
  <div class="ad" :style="{'background-image':'url('+getBannerUrl()+')'}" >
    <div class="textDiv">
      <!-- <div class="adTitle">Ad space for lease</div> -->
      <!-- <div class="subTitle">We’ve got everything you need to travel Japan in 2023</div> -->
      <button class="explore" @click="goDetails(adv)">{{$t('app.exploreNow')}}</button>
    </div>
  </div>
</template>

<script>
import type_0 from "../assets/img/type_0.png";
import apis from "@/utils/apis";
import { hostUrl } from "@/utils/apis";

let {  getDataCount } = apis;

export default {
  name: "adSpace",
  props:["adv"],
  data() {
    return {
      adImg: this.adv ? this.adv : 'https://bing.com/th?id=OSGI.C9E7A1A2E0E14D97DA9392417D14BC19&h=1000&w=750&c=1&rs=1&o=3',
    }
  },
  methods:{
    getBannerUrl(){
      // console.log(this.adImg);
      if(this.adv && this.adv.banner){
        return this.adv.banner
      }else{
        return 'https://bing.com/th?id=OSGI.C9E7A1A2E0E14D97DA9392417D14BC19&h=1000&w=750&c=1&rs=1&o=3'
      }
    },
    goDetails(item){
      let types = {
        "en": 2,
        "cn": 0,
        "jp": 1,
        "hk": 3
      };
      let lang = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      if(item.url){
        let urlStr = item.url;
        let url = new URL(urlStr);

        if (urlStr.indexOf(hostUrl) > -1) {
          let urlId = url.searchParams.get("id");
          let urlType = url.searchParams.get("type");
          let type = -1;
          if (urlStr.indexOf('/detailsPage') > -1) {
            if (urlType == 5) {
              type = 1;
            } else{
              type = 2;
            }
          } else if (urlStr.indexOf('/blockDetail') > -1) {
            type = 2;
          } else if (urlStr.indexOf('/orderXq') > -1) {
            type = 4;
          };
          if (type != -1) {
            let params = {
              type: type,
              id: urlId,
              language: lang,
            };
            getDataCount({ ...params }, (response) => {
              if (response && response.data > 0) {
                window.location.href = item.url
              } else {
                this.$dialog({
                  title: "",
                  message: this.$t('app.data_null_tips'),
                  cancelButtonText: this.$t('app.cancel')
                }).then(() => {}).catch(() => {});
              }
            });
          }
        } else {
          window.location.href = item.url
        }
      }else if(item.refId){
        let params = {
          type: 2,
          id: item.refId,
          language: lang,
        };
        getDataCount({ ...params }, (response) => {
          if (response && response.data > 0) {
            this.$router.push({
              path: "detailsPage",
              query: {
                type: item.refType,
                id: item.refId,
              },
            });
          } else {
            this.$dialog({
              title: "",
              message: this.$t('app.data_null_tips'),
              cancelButtonText: this.$t('app.cancel')
            }).then(() => {}).catch(() => {});
          }
        });
      }
    }
  }


}
</script>

<style scoped lang="less">
.ad {
  margin-top:0.6rem;
  width: 100%;
  // height: 7.5rem;
  padding-top: 64%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  
  .textDiv {
    text-align: left;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    position: absolute;
    bottom: 0.5rem;

    .adTitle {
      font-size: 0.7rem;
      font-weight: 600;
    }

    .subTitle {
      font-size: 0.3rem;
      line-height: 0.5rem;
      margin-top: 0.1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .explore {
      width: 2.8rem;
      height: 0.9rem;
      background: white;
      color: black;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      border: none;
    }

  }


}

</style>