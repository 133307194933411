<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.homeTitle1") }}</div>
      <div class="mr-04 fs-025" @click="searchMore">{{ $t("app.More") }}</div>
    </div>
    <div class="distTitle2">{{ $t("app.homeSubtitle1") }}</div>

    <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in districtList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="imgDiv">
          <img :src="item.img" class="img" alt="" />
          <div class="title">
            <div class="subtitle">{{ item.name }}</div>
            <div class="desc">{{ item.desc | filtersText }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "districtPart",
  props: ["districtList"],
  data() {
    return {};
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "detailsPage",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    searchMore(){
      this.$router.push("/areaList")
      // const _this = this
      // this.$router.push({
      //     path:'/result',
      //     query:{
      //         searchKey:"",
      //         name:'c',
      //         index:'1'
      //     }
      // })
    }
  },
};
</script>

<style scoped lang="less">
.districtDiv {
  margin-top: 0.4rem;
  text-align: start;

  .distTitle1 {
    color: black;
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.4rem;
  }

  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.24rem 0.4rem 0.24rem 0.4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin: 0.075rem;
    width: 4.25rem;
    height: 4.25rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }

    .imgDiv {
      position: relative;
      width: 4.25rem;

      .title {
        position: absolute;
        bottom: 0;
        height: 1.35rem;
        // line-height: 0.3rem;
        // background: linear-gradient(rgba(0, 0, 0,0.3) ,rgba(0, 0, 0,1));
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.7) 80%,rgba(0, 0, 0,.0) 100%);
        // background: rgba(0, 0, 0, 0.5);
        color: white;
        // font-size: 0.3rem;
        text-align: start;
        padding: 0.1rem 0.2rem;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        min-width: 0;
        border-bottom-left-radius: 0.16rem;
        border-bottom-right-radius: 0.16rem;
        .subtitle{
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .desc {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: 0.64rem;
          &:first-child {
            font-size: 0.3rem;
          }
          &:last-child {
            margin-top: 0.13rem;
            font-size: 0.24rem;
            line-height: 0.32rem;
          }
        }
      }

      .img {
        display: flex;
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 0.16rem;
        object-fit: cover;
      }
    }
  }
}
</style>
