<template>
  <div style="display: flex;justify-content: center">
    <div class="search" @click="toSearch()">
      <div style="color: #a0a1a1;font-size: 15.5px;padding-left:1rem;">{{ $t('app.whereTo') }}</div>
      <span class="search_icon"><img src="../../assets/img/search.png" alt=""></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchBtn",


  methods: {
    toSearch() {
      this.$router.push('/search')
    }

  }
}
</script>

<style scoped lang="less">
.search {
  position: relative;
  margin-top: .2rem;
  width: 90%;
  height: 0.9rem;
  background: white;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  position: absolute;
  top: 3.3rem;
  font-size: 0.26rem;
  .search_icon{
    position: absolute;
    height: 0.9rem;
    height: 0.9rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      height: .3rem;
    }
  }
  // .selectInputIcon {
    // position: relative;
    // margin-left: 1rem;
    // width: 0.28rem;

    // &::before {
    //   content: '';
    //   width: 0.2rem;
    //   height: 0.2rem;
    //   border-radius: 50%;
    //   border: 1px solid #000000;
    //   display: block;
    // }

    // &::after {
    //   content: '';
    //   width: 0.05rem;
    //   height: 0.01rem;
    //   background: #000000;
    //   display: block;
    //   position: absolute;
    //   transform: rotate(45deg);
    //   right: 0.02rem;
    //   bottom: 0;
    // }


  // }

}

</style>